// PEZZ web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyAj52EB7-RmBsJaG9lSojBfY_S1PWf5U6E",
    authDomain: "pezz-stage.firebaseapp.com",
    databaseURL: "https://pezz-stage.firebaseio.com",
    projectId: "pezz-stage",
    storageBucket: "pezz-stage.appspot.com",
    messagingSenderId: "580043556569",
    appId: "1:580043556569:web:f8135905b0e23573ac60c8",
    measurementId: "G-X94HNZPYLV"
};